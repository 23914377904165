import React from 'react';
import { NavLink } from 'react-router-dom';

const KatteriNav = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <hr />
    </div>
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <p className="d-block d-lg-inline mr-2 mb-0"><span className="flag-icon flag-icon-se" /></p>
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/start">Planerade kullar</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/till-salu">Till salu</NavLink>
      {/* <NavLink className="d-block d-lg-inline mr-2" to="/katteri/rasbeskrivning">Rasbeskrivning</NavLink> */}
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/om">Om oss</NavLink>
      <br />
      <p className="d-block d-lg-inline mr-2 mb-0"><span className="flag-icon flag-icon-gb" /></p>
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/my-cats">My cats</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/for-sale">For Sale</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/katteri/about-us">About us</NavLink>
    </div>
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <hr />
    </div>
  </div>
);

export default KatteriNav;
