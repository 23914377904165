import React from 'react';

const RaceInfo = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 col-lg-8">
      <h2>Rasbeskrivning</h2>
      {/* <p>Förut hade jag en ras beskrivning här.</p>
      <p>Idag har jag en förklaring till varför jag inte har det.</p>
      <br />
      <br />
      <p>Alla katter är framavlade för PRECIS samma sak,dvs att vara trevliga och vackra sällskapskatter.</p>
      <br />
      <br />
      <p>Det enda som skiljer kattraser är deras utseende i stort sett.</p>
      <p>Vi avlar nämligen inte katten för olika ändamål såsom hunden eller hästen.</p>
      <br />
      <br />
      <p>Sen att det blir mer av olika saker inom vissa raser på grund av att man hela tiden  dubblerar vissa gener/egenskaper så som exempelvis prata mycket eller vara social inom en liten stängd population,dvs grupp katter är ju en annan sak.</p>
      <br />
      <br />
      <p>Men tyvärr är det så att alla katter i denna grupp fort blir släkt med varandra.</p>
      <br />
      <br />
      <p>Detta gör i sin tur att inavelsgraden tillslut är så pass hög att djuren dör vid yngre ålder än förr och får rasssjukdomar.</p>
      <br />
      <br />
      <p>Allt detta trots att vi uppfödare avlar med noll inavelsgrad i stamtavlan och på friska indevider.</p>
      <br />
      <br />
      <p>(Det var lätt & kul att vara uppfödare förr, då var nämligen inavelsgraden inte så stor så det märktes,men allt för få uppfödare avlar djur långt ifrån varandra släktmässigt och gör kontroll avel. Dvs blandar in nytt blod ifrån andra raser)</p>
      <br />
      <br />
      <p>Jag önskar det inte var såhär,då det är oerhört roligt att avla inom sin ras hela tiden och att ställa ut samt att kunna göra reklam för just SIN ras!.</p>
      <br />
      <br />
      <p>Det man kallar rent/renrasig.</p>
      <p>Rent kommer ifrån en rätt mörk historia om rasavel.</p>
      <br />
      <br />
      <p>Att använda ordet rent låter ju oerhört bra...och det har lyckats få människor att tro renrasiga djur är bättre än blandraser.</p>
      <br />
      <br />
      <p>Många tror även raser tom är något naturen fixat själv.</p>
      <p>Men så är det inte.</p>
      <p>Det är vi som delar upp arten katt I flera raser så vi kan få olika utseenden.</p>
      <br />
      <br />
      <p>Vi bevarar alltså olika anlag, mutationer och tom defekter som annars skulle försvinna.</p>
      <br />
      <br />
      <p>På så vis får vi oerhört många olika utseenden och även enklare innekatter oftare då vi avlar på hanterbara trygga oerhört mysiga & sociala katter.</p>
      <br />
      <br />
      <p>Detta leder till att vi kan mer förutspå vad vi får för ungar både till sättet och utseende.</p>
      <br />
      <br />
      <p>Visst låter detta jättebra?!</p>
      <p>Och det ÄR det till en viss gräns..och gränsen kommer när  inavelsgraden blir för hög.</p>
      <br />
      <br />
      <p>Detta trotts att vi avlar med NOLL inavelsgrad I stamtavlan som sagt.</p>
      <p>Alla uppfödare borde ta ansvar och avla ut.</p>
      <br />
      <br />
      <p>Att ha flera indevider som grundare till rasen är en fördel.</p>
      <p>Det gör att flera gener finns i grundlinjen.</p>
      <br />
      <br />
      <p>När vi dubblerar alla bra egenskaper och ett visst utseende så tar vi även bort massor av andra anlag och gener.</p>
      <br />
      <br />
      <p>Inavelsgradsproblem och rasstandard problem ser vi idag inom många rasdjur.</p>
      <br />
      <br />
      <p>Gör en enkel googling på inavel inom rasaveln och du kommer finna mer information.</p>
      <br />
      <br />
      <p>Det vi uppfödare måste göra i är att blanda in nytt blod,dvs tillföra nya gener genom avelsdjur som inte andvänds inom rasens stängda population tidigare.</p>
      <p>Givetvis ska dessa avelsdjur även vara friska!</p>
      <p>På det viset får man starkare och mer levnadskraftiga katter igen.</p>
      <p>Men inte det rastypiska utseende man eftersträvar...Men precis lika underbara till sättet</p>
      <br />
      <br />
      <p>Jag kommer utavla Lykoi med huskatt år 2025.</p>
      <br />
      <br />
      <p>Peterbald utavlar jag med Siames och Oriental och jag arbetar på att få pälsade ungar.</p> */}
      {/* <h3>La Perm</h3>
      <p>
        La Permen är intelligent, vänlig och lekfull, och tackar varken nej till apportlek, koppelpromenad eller en mysstund i soffan. Den är en avslappnad och självsäker varelse och en utmärkt problemlösare.
        <br />
        La Permen finns som både semilång- och korthår. Korthåren har vågig päls och svans som en flaskborste, medan semilånghåren kan ha vågor, lockar eller hårt krull över hela kroppen och en yvig plymsvans samt halskrage och krusiga tofsar bakom öronen. Pälsen skall vara en blandning av mjukhet och krispighet och får inte vara för tjock och tät – den skall lätt gå att dra fingrarna igenom och känna huden under. Det kan födas ungar med rak, lockig eller utan päls (BS-born straight, BC-born curly, BB-born bald), där de nakna får lockig päls med tiden. La Permens rexanlag är dominant, så det behövs endast en lockig förälder för att få lockiga barn och det är även därför det kan födas släta ungar efter två lockiga föräldrar.
        <br />
        Ibland kommer krullet sent i åldern runt 2-3 år, men det är värt väntan. Pälsvården är enkel, en La Perm fäller väldigt lite, knappt märkbart. Är en katt som kan vara som en &quot;vanlig&quot; utekatt. La Perm, är en fin familjekatt.
        <br />
        Du kan beskriva La Perms utseende som en katt som fått en elektrisk stöt, tack vare sin päls utseende. Det lockiga håret katten har är tack vare en genetisk mutation och inte för att de utvecklats avsiktligt av uppfödare.
        <br />
        Denna ras föds utan hår eller med kort hår och lockarna utvecklas naturligt när katten blir äldre. De älskar att följa efter dig vart du än går eller titta på och övervaka allt du gör. Bli inte förvånad om du en dag ser katten öppna en låda då den lär sig av att observera och imitera det du gör. La Perm är aktiv av naturen men älskar även att sitta i ditt knä och bli klappad och få uppmärksamhet.
        <br />
        La Perm är i regel frisk då det inte finns många för rasen kända specifika sjukdomar eller andra hälsoåkommor, i jämförelse med vissa andra kattraser. La Perm är en tystlåten katt ras. Katten är känd för att kunna hantera att deras ägare gör något annat så länge som de engagerar dem i olika aktiviteter. Regelbunden motion är nödvändigt för att hålla la perm frisk och i god form.
        <br />
        <br />
        La Perms historia
        <br />
        Speedy var en brun tabby bondkatt som föddes i Dalles i Oregon. År 1982 fick Speedy en kull kattungar varav en föddes utan päls med tabbytecken på huden och med stora öron. I och med att katten växte blev pälsen lockig, varför den döptes till Curly.
        <br />
        Curly fick så småningom sin egen kull kattungar med olika hankatter i området. Resultatet var en kull kattungar som alla hade lockig päls tack vare den dominanta genen, vilken är ett resultat av en naturlig mutation. Katten gavs sedan namnet La perm och fick fullt erkännande av The International Cat Association år 2002.
        <br />
        Rasen är även erkänd av Cat Fanciers Association.
        <br />
        Om du är en person som älskar att skratta och har humor så kan La Perm vara rätt ras för dig. Katten är känd för att vara clownlik och busig och har talang för att använda sina tassar för att öppna dörrar. De är även kända för att nudda vid sina ägare med tassarna närhelst de behöver någonting. La Perm älskar att följa sina ägare runt i huset och har en tendens att vaka över allt du sysslar med. Katten är måttligt aktiv och tycker om att leka apport med sina ägare. La perm är tillgiven, mild, vänlig och tålmodig med sina människor.
        <br />
      </p>
      <br /> */}
      {/* <h2>Peterbald</h2>
      <p>
        Peterbald är en ras som är väldigt social. De älskar att ligga i knät eller under täcket hos dig, och man får så mycket kärlek från dem. De är inte blyga och sitter gärna i hallen och hälsar främmande välkomna in.
        <br />
        <br />
        De pratar lite, men inte alls överdrivet mycket. De har en aktivitetsnivå som gör att de lämpar sig utmärkt som innekatter.
        <br />
        <br />
        De är väldigt nyfikna och följer däremot gärna med ut på en koppel promenad.
        <br />
        <br />
        Lite fakta om rasens pälsvarianter:
        <br />
        <br />
        Pälsen är spännande och omväxlande. Peterbald nämns ibland som en nakenras, men det är fel då de flesta som föds i rasen har päls. Men det kan ibland födas någon naken katt. De kallas gummi eller Rubber-katter.
        <br />
        <br />
        Flockpälsen är näst intill naken, men katten har päls på ben, nosen och eventuellt lite på kroppen.
        <br />
        <br />
        Velour är små korta hårstrån över hela kroppen, och det känns som att klappa en mjuk sammetskudde.
        <br />
        <br />
        Efter den har vi brush som är lockig päls. Finns light brush, brush och heavy brush, även kallad teddypäls då den är mjuk och luddig.
        <br />
        <br />
        De kan även födas med helt vanlig päls sia/oriental päls.
        <br />
        <br />
        Den vanliga pälsen kan fälla som normal kattpäls. De andra varianterna fäller inget eller mycket mindre än normalt och är därför en ras som kan passa bättre om man är allergisk.
        <br />
        <br />
        Att bada katten rekommenderar jag även att göra om man vill ha så lite allergener i sitt hem som möjligt.
        <br />
        <br />
        De kommer ofta väl överens med andra katter, hundar och barn, då det är en ras som oftast har ett väldigt mjukt temperament och en charmerande lekfull sida.
        <br />
        <br />
        En kompis rekommenderas att ha minst eftersom de är så sociala och behöver mycket närhet.
        <br />
        <br />
        Min beskrivning av denna ras är att de är som en liten bebis.
        <br />
        <br />
        Vi är enbart några få uppfödare av denna ras i Sverige och jag är oerhört stolt att vara en av dem.
        <br />
        <br />
        Det är en ras som är frisk och har relativt stor genetisk mångfald på grund av att vi får avla med siames, balines, oriental samt att alla färger och mönster är tillåtna.
        <br />
        <br />
        Jag älskar den här rasen och rekommenderar den varmt.
        <br />
        <br />
        Välkommen in på Facebook-sidan Peterbald Scandinavian och titta på uppfödarnas olika varianter av rasen, och jag hoppas du hittar just din favorit.
        <br />
        <br />
        <br />
        PETERBALD – historia och ursprung
        <br />
        <br />
        I Sankt Petersburg parades 1994 en Don Sphynx-hane vid namn Afinogen Myth med Radma von Jagerhov, en orientalisk korthårshona, och resultatet lade grunden till dagens Peterbald. Namnet Peterbald är en kombination av staden där rasen grundlades samt att rasen har ett dominant nakenanlag som får fram alla möjliga sorters päls och icke päls.
        <br />
        <br />
        1996 erhöll rasen sin första standard i det ryska förbundet SFF, och 1997 godkändes rasen av CFA.
        <br />
        <br />
        Jag hoppas du kommer att gilla min typ av Peterbald. Jag fokuserar mycket på mentalitet och hälsotester. Det är ingen nakenkatt utan en katternas variation av både naken och päls.
        <br />
        <br />
      </p> */}
      {/* <h2>Lykoi</h2>
      <p>
        Lykoi är en riktigt häftig katt utseendemässigt och ofta mycket &quot;kattig&quot; även till sättet. Lite av en urkatt.
        <br />
        <br />
        En utmärkt jägare och älskar att vara ute i naturen, men kan också vara en innekatt.
        <br />
        <br />
        Lykoi utavlas ständigt med huskatt i alla dess former, så hur en Lykoi ser ut och är kan variera mycket. Men deras utseende blir unikt på grund av den mer eller mindre pälslösa masken som de har i ansiktet och deras tassar och ben som ofta har mindre päls också.
        <br />
        <br />
        Pälsen är ovanligt mjuk och silkeslen, vilket förvånar många då den ser sträv ut! Men helt tvärtom.
        <br />
        <br />
        Utseendemässigt är färgen black roan vanligast, som är en blandning av mörkt färgade hårstrån och vita som i sin tur ger den hårlösa masken i ansiktet ett mycket levande utseende, nästan människolikt. Alla färger och mönster är dock tillåtna sedan år 2022, men de måste ha roan päls, d.v.s. dessa vita hårstrån i den färgade pälsen och helst 50% av den färgade pälsen bör vara roan.
        <br />
        <br />
        Som uppfödare är det viktigt att umgås mycket med kattungar, så att de blir riktigt socialiserade, eftersom det inte var länge sedan denna ras var en vildkatt.
        <br />
        <br />
        Skötseln är som för vilken katt som helst. Väldigt enkel; en mer naken Lykoi kan behöva bada ibland.
        <br />
        <br />
        Lykoi-kattens avelsarbete är väldigt spännande, då det kräver mycket mer än övriga raser i form av utavel för att skapa nya linjer, vilket är tidskrävande och kostsamt eftersom nya katter noga ska hälsotestas innan de kommer in i linjerna.
        <br />
        <br />
        Mitt mål är att få fram sociala Lykoi-katter med frisk hud.
        <br />
        <br />
        En Lykoi kan vara ensamkatt, men jag personligen rekommenderar alltid att ha en kattvän, då jag sett hur mycket utbyte katter har av en kattkompis. Men som med allt, alla är unika.
        <br />
        <br />
        Hoppas vi ses på någon utställning framöver och välkommen in i Facebook-gruppen Scandinavia Lykoi.
      </p> */}
      {/* <a
          href="https://www.sverak.se/kattraser/peterbald-peb/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.sverak.se/kattraser/peterbald-peb/
        </a> */}
    </div>
  </div>
);

export default RaceInfo;
